import React, { Component, Fragment } from 'react'
import { Routes, Route } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import LoginPage from '../pages/LoginPage';
import ContactPage from '../pages/ContactPage';
import PurchasePage from '../pages/PurchasePage';
import ProdcutDetailsPage from '../pages/ProdcutDetailsPage';
import NotificationPage from '../pages/NotificationPage';
import FavoritePage from '../pages/FavoritePage';
export class AppRoute extends Component {
  render() {
    return (
      <Fragment>
        <Routes>
          <Route path='/' element={<HomePage exact />} />
          <Route path='/login' element={<LoginPage exact />} />
          <Route path='/contact' element={<ContactPage exact />} />
          <Route path='/purchase' element={<PurchasePage exact />} />
          <Route path='/product_details' element={<ProdcutDetailsPage exact />} />
          <Route path='/notification' element={<NotificationPage exact />} />
          <Route path='/favorite' element={<FavoritePage exact />} />
        </Routes>
      </Fragment>
    )
  }
}

export default AppRoute