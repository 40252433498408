import React, { Component, Fragment } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import dress from '../../assets/images/dress3.jpg';

export class Categories extends Component {
  render() {
    return (
      <Fragment>
        <Container className="text-center" fluid={"true"}>
            <div className="section-title text-center mb-55">
                <h2>Categories</h2>
                <p>Most famous categories</p>
            </div>
            <Row>
                <Col key={1} xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Row>
                        <Col className="p-0" key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
                            <Card className="h-100 w-100 text-center">
                                <Card.Body>
                                    <img className="center" src={dress} alt="featured prodct"/>
                                    <h5 className='category-name'>MOBILE</h5>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col className="p-0" key={2} xl={3} lg={3} md={3} sm={6} xs={6}>
                            <Card className="h-100 w-100 text-center">
                                <Card.Body>
                                    <img className="center" src={dress} alt="featured prodct"/>
                                    <h5 className='category-name'>MOBILE</h5>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col className="p-0" key={3} xl={3} lg={3} md={3} sm={6} xs={6}>
                            <Card className="h-100 w-100 text-center">
                                <Card.Body>
                                    <img className="center" src={dress} alt="featured prodct"/>
                                    <h5 className='category-name'>MOBILE</h5>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col className="p-0" key={4} xl={3} lg={3} md={3} sm={6} xs={6}>
                            <Card className="h-100 w-100 text-center">
                                <Card.Body>
                                    <img className="center" src={dress} alt="featured prodct"/>
                                    <h5 className='category-name'>MOBILE</h5>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col key={2} xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Row>
                        <Col className="p-0" key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
                            <Card className="h-100 w-100 text-center">
                                <Card.Body>
                                    <img className="center" src={dress} alt="featured prodct"/>
                                    <h5 className='category-name'>MOBILE</h5>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col className="p-0" key={2} xl={3} lg={3} md={3} sm={6} xs={6}>
                            <Card className="h-100 w-100 text-center">
                                <Card.Body>
                                    <img className="center" src={dress} alt="featured prodct"/>
                                    <h5 className='category-name'>MOBILE</h5>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col className="p-0" key={3} xl={3} lg={3} md={3} sm={6} xs={6}>
                            <Card className="h-100 w-100 text-center">
                                <Card.Body>
                                    <img className="center" src={dress} alt="featured prodct"/>
                                    <h5 className='category-name'>MOBILE</h5>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col className="p-0" key={4} xl={3} lg={3} md={3} sm={6} xs={6}>
                            <Card className="h-100 w-100 text-center">
                                <Card.Body>
                                    <img className="center" src={dress} alt="featured prodct"/>
                                    <h5 className='category-name'>MOBILE</h5>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col key={1} xl={6} lg={6} md={12} sm={12} xs={12}>
                    <Row>
                        <Col className="p-0" key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
                        <Card className="h-100 w-100 text-center">
                            <Card.Body>
                                <img className="center" src={dress} alt="featured prodct"/>
                                <h5 className='category-name'>MOBILE</h5>
                            </Card.Body>
                         </Card>
                        </Col>

                        <Col className="p-0" key={2} xl={3} lg={3} md={3} sm={6} xs={6}>
                        <Card className="h-100 w-100 text-center">
                            <Card.Body>
                                <img className="center" src={dress} alt="featured prodct"/>
                                <h5 className='category-name'>MOBILE</h5>
                            </Card.Body>
                         </Card>
                        </Col>

                        <Col className="p-0" key={3} xl={3} lg={3} md={3} sm={6} xs={6}>
                        <Card className="h-100 w-100 text-center">
                            <Card.Body>
                                <img className="center" src={dress} alt="featured prodct"/>
                                <h5 className='category-name'>MOBILE</h5>
                            </Card.Body>
                         </Card>
                        </Col>

                        <Col className="p-0" key={4} xl={3} lg={3} md={3} sm={6} xs={6}>
                        <Card className="h-100 w-100 text-center">
                            <Card.Body>
                                <img className="center" src={dress} alt="featured prodct"/>
                                <h5 className='category-name'>MOBILE</h5>
                            </Card.Body>
                         </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
      </Fragment>
    )
  }
}

export default Categories