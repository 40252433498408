import React, { useEffect } from 'react'

function MegaMenu() {

    useEffect(() => {
        MegaMenu();
    }, []);

    const MegaMenu = () => {
        var acc = document.getElementsByClassName("accordion");
        var accNum = acc.length;
        var i;
        for(i=0;i<accNum;i++){
             acc[i].addEventListener("click",function () {
            
                  this.classList.toggle("active");
                  var panel = this.nextElementSibling;
                  if(panel.style.maxHeight){
                       panel.style.maxHeight = null;
                  }else{
                       panel.style.maxHeight= panel.scrollHeight+ "px";
                  }
             })
        }
    }
  
    return (
      <div className="accordionMenuDiv">
        <div className="accordionMenuDivInside">

           <button className="accordion">
                 <i className="accordionMenuIcon fa fa-tshirt" />&nbsp; Men's Clothing 
            </button>
            <div className="panel">
                <ul>
                    <li><a href="/" className="accordionItem" > Mans Tshirt 1</a></li>
                    <li><a href="/" className="accordionItem" > Mans Tshirt 2</a></li>
                </ul>
            </div>

            <button className="accordion">
                 <i className="accordionMenuIcon fa fa-tshirt" />&nbsp; Men's Clothing 
            </button>
            <div className="panel">
                <ul>
                    <li><a href="/" className="accordionItem" > Mans Tshirt 1</a></li>
                    <li><a href="/" className="accordionItem" > Mans Tshirt 2</a></li>
                </ul>
            </div>

            <button className="accordion">
                 <i className="accordionMenuIcon fa fa-tshirt" />&nbsp; Men's Clothing 
            </button>
            <div className="panel">
                <ul>
                    <li><a href="/" className="accordionItem" > Mans Tshirt 1</a></li>
                    <li><a href="/" className="accordionItem" > Mans Tshirt 2</a></li>
                </ul>
            </div>

            <button className="accordion">
                 <i className="accordionMenuIcon fa fa-tshirt" />&nbsp; Men's Clothing 
            </button>
            <div className="panel">
                <ul>
                    <li><a href="/" className="accordionItem" > Mans Tshirt 1</a></li>
                    <li><a href="/" className="accordionItem" > Mans Tshirt 2</a></li>
                </ul>
            </div>

        </div>
      </div>
    )
}

export default MegaMenu