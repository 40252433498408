import React, { Component, Fragment } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import dress4 from '../../assets/images/dress4.jpg';
import dress5 from '../../assets/images/dress5.jpg';
import childSock from '../../assets/images/Child_new.png';
import menSock from '../../assets/images/men_bamboo3.png';

export class Collection extends Component {
  render() {
    return (
      <Fragment>
        <Container className="text-center" fluid={"true"}>
            <div className="section-title text-center md-55">
                <h2>PRODUCT COLLECTION</h2>
                <p>Our Exlcusive Collection For You</p>
            </div>
            <Row>
                <Col className="p-0" xl={3} lg={3} md={3} sm={6} xs={6}>
                <Card className="image-box card">
                        <img className="center" src={dress4} alt="featured prodct"/>
                        <Card.Body>
                            <p className="product-name-on-card">High quality women dress.</p>
                            <p className="product-price-on-card">Price: $99.99</p>
                        </Card.Body>
                    </Card>
                </Col>

                <Col className="p-0" xl={3} lg={3} md={3} sm={6} xs={6}>
                <Card className="image-box card">
                        <img className="center" src={dress5} alt="featured prodct"/>
                        <Card.Body>
                            <p className="product-name-on-card">High quality women dress.</p>
                            <p className="product-price-on-card">Price: $99.99</p>
                        </Card.Body>
                    </Card>
                </Col>

                <Col className="p-0" xl={3} lg={3} md={3} sm={6} xs={6}>
                <Card className="image-box card">
                        <img className="center" src={childSock} alt="featured prodct"/>
                        <Card.Body>
                            <p className="product-name-on-card">High quality Children Socks</p>
                            <p className="product-price-on-card">Price: $99.99</p>
                        </Card.Body>
                    </Card>
                </Col>

                <Col className="p-0" xl={3} lg={3} md={3} sm={6} xs={6}>
                <Card className="image-box card">
                        <img className="center" src={menSock} alt="featured prodct"/>
                        <Card.Body>
                            <p className="product-name-on-card">High quality Men Cottton Socks</p>
                            <p className="product-price-on-card">Price: $99.99</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
      </Fragment>
    )
  }
}

export default Collection