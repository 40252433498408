import React, { useEffect } from 'react'

function MegaMenuMobile() {

     useEffect(() => {
          MegaMenu();
     }, []);
     

     const MegaMenu = () => {
          let acc = document.getElementsByClassName("accordionMobile");
          let accNum = acc.length;
          for(let i=0; i < accNum; i++){
               acc[i].addEventListener("click",function(){

                    this.classList.toggle("active");
                    var panel = this.nextElementSibling;
                    if(panel.style.maxHeight){
                         panel.style.maxHeight = null;
                    }else{
                         panel.style.maxHeight= panel.scrollHeight+ "px"
                    }
               })
          }
     }

     return (
          <div className="accordionMenuDivMobile">
               <div className="accordionMenuDivInsideMobile">

                    <button className="accordionMobile">
                         <i className="accordionMenuIconMobile fa fa-tshirt" />&nbsp; Men's Clothing 
                              </button>
                    <div className="panelMobile">
                         <ul>
                              <li><a href="/" className="accordionItemMobile" > Mans Tshirt 1</a></li>
                              <li><a href="/" className="accordionItemMobile" > Mans Tshirt 2</a></li>
                         </ul>
                    </div>

                    <button className="accordionMobile">
                         <i className="accordionMenuIconMobile fa fa-tshirt" />&nbsp; Men's Clothing 
                                        </button>
                    <div className="panelMobile">
                         <ul>
                              <li><a href="/" className="accordionItemMobile" > Mans Tshirt 1</a></li>
                              <li><a href="/" className="accordionItemMobile" > Mans Tshirt 2</a></li>
                         </ul>
                    </div>

                    <button className="accordionMobile">
                         <i className="accordionMenuIconMobile fa fa-tshirt" />&nbsp; Men's Clothing 
                                        </button>
                    <div className="panelMobile">
                         <ul>
                              <li><a href="/" className="accordionItemMobile" > Mans Tshirt 1</a></li>
                              <li><a href="/" className="accordionItemMobile" > Mans Tshirt 2</a></li>
                         </ul>
                    </div>

                    <button className="accordionMobile">
                         <i className="accordionMenuIconMobile fa fa-tshirt" />&nbsp; Men's Clothing 
                                        </button>
                    <div className="panelMobile">
                         <ul>
                              <li><a href="/" className="accordionItemMobile" > Mans Tshirt 1</a></li>
                              <li><a href="/" className="accordionItemMobile" > Mans Tshirt 2</a></li>
                         </ul>
                    </div>

                    <button className="accordionMobile">
                         <i className="accordionMenuIconMobile fa fa-tshirt" />&nbsp; Men's Clothing 
                                        </button>
                    <div className="panelMobile">
                         <ul>
                              <li><a href="/" className="accordionItemMobile" > Mans Tshirt 1</a></li>
                              <li><a href="/" className="accordionItemMobile" > Mans Tshirt 2</a></li>
                         </ul>
                    </div>

                    <button className="accordionMobile">
                         <i className="accordionMenuIconMobile fa fa-tshirt" />&nbsp; Men's Clothing 
                                        </button>
                    <div className="panelMobile">
                         <ul>
                              <li><a href="/" className="accordionItemMobile" > Mans Tshirt 1</a></li>
                              <li><a href="/" className="accordionItemMobile" > Mans Tshirt 2</a></li>
                         </ul>
                    </div>

               </div>

          </div>
          )
     
}

export default MegaMenuMobile