import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap'
import logo from '../../assets/images/logo-tanin-ec3.png';
import MegaMenuMobile from '../home/MegaMenuMobile';

export class NavMenuMobile extends Component {
  constructor(props){
    super(props);
    this.state={
         SideNavState: "sideNavClose",
         ContentOverState: "ContentOverlayClose"
    }
  }


  MenuBarClickHandler=()=>{
      this.SideNavOpenClose();
  }

  ContentOverlayClickHandler=()=>{
      this.SideNavOpenClose();
      
  }


  SideNavOpenClose=()=>{
      let SideNavState = this.state.SideNavState;
      if(SideNavState==="sideNavOpen"){
          this.setState({SideNavState:"sideNavClose",ContentOverState:"ContentOverlayClose"})

      }
      else{
          this.setState({SideNavState:"sideNavOpen",ContentOverState:"ContentOverlayOpen"})
      }
  }
  render() {
    return (
      <Fragment>
        <div className='topSectionDown'>

          <Container fluid={"true"} className="fixed-top shadow-sm p-2 mb-0" bg="white">
            <Row>
              <Col className="p-1 mt-2" lg={4} md={4} sm={12} xs={12}>
                <Button onClick={this.MenuBarClickHandler} className="btn"><i className="fa fa-bars"></i></Button>
                <Link to="/"><img className="nav-logo" src={logo} alt='logo'/></Link>
                <Button className="cart-btn"><i className="fa fa-shopping-cart"> 3 Items</i></Button>
              </Col>
            </Row>
            </Container>

            <div className={this.state.SideNavState}>
              <MegaMenuMobile />  
            </div>
              <div onClick={this.ContentOverlayClickHandler} className={this.state.ContentOverState}>

               </div>

            </div>
            
      </Fragment>
    )
  }
}

export default NavMenuMobile