import React, { useEffect } from 'react'

function MegaMenuAll() {
 
     useEffect(() => {
          MegaMenu();
     }, [])

     const MegaMenu = () => {
          var acc = document.getElementsByClassName("accordionAll");
          var accNum = acc.length;
          var i;
          for(i=0;i<accNum;i++){

               acc[i].addEventListener("click",function (){
                    this.classList.toggle("active");
                    var panel = this.nextElementSibling;
                    if(panel.style.maxHeight){
                         panel.style.maxHeight = null;
                    }else{
                         panel.style.maxHeight= panel.scrollHeight+ "px"
                    }
               })
          }
     }


     return (
          <div className="accordionMenuDivAll">
          <div className="accordionMenuDivInsideAll">

               <button className="accordionAll">
                    <i className='accordionMenuIconAll fa fa-shopping-bag' />&nbsp; Men's Clothing 
               </button>
               <div className="panelAll">
                    <ul>
                         <li><a href="/" className="accordionItemAll" > Mans Tshirt 1</a></li>
                         <li><a href="/" className="accordionItemAll" > Mans Tshirt 2</a></li>
                    </ul>
               </div>

               <button className="accordionAll">
                    <i className='accordionMenuIconAll fa fa-shopping-bag' />&nbsp; Men's Clothing 
               </button>
               <div className="panelAll">
                    <ul>
                         <li><a href="/" className="accordionItemAll" > Mans Tshirt 1</a></li>
                         <li><a href="/" className="accordionItemAll" > Mans Tshirt 2</a></li>
                    </ul>
               </div>

               <button className="accordionAll">
                    <i className='accordionMenuIconAll fa fa-shopping-bag' />&nbsp; Men's Clothing 
               </button>
               <div className="panelAll">
                    <ul>
                         <li><a href="/" className="accordionItemAll" > Mans Tshirt 1</a></li>
                         <li><a href="/" className="accordionItemAll" > Mans Tshirt 2</a></li>
                    </ul>
               </div>

               <button className="accordionAll">
                    <i className='accordionMenuIconAll fa fa-shopping-bag' />&nbsp; Men's Clothing 
               </button>
               <div className="panelAll">
                    <ul>
                         <li><a href="/" className="accordionItemAll" > Mans Tshirt 1</a></li>
                         <li><a href="/" className="accordionItemAll" > Mans Tshirt 2</a></li>
                    </ul>
               </div>

               <button className="accordionAll">
                    <i className='accordionMenuIconAll fa fa-shopping-bag' />&nbsp; Men's Clothing 
               </button>
               <div className="panelAll">
                    <ul>
                         <li><a href="/" className="accordionItemAll" > Mans Tshirt 1</a></li>
                         <li><a href="/" className="accordionItemAll" > Mans Tshirt 2</a></li>
                    </ul>
               </div>

          </div>

          </div>
          )

}

export default MegaMenuAll