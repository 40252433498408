import React, { Component, Fragment } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'; 
import sock from "../../assets/images/product/MG09_1.png";
import dress from '../../assets/images/dress3.jpg';
import { Link } from 'react-router-dom';
class FeaturedProducts extends Component {
  render() {
    return (
      <Fragment>
        <Container className="text-center" fluid={"true"}>
            <div className="section-title text-center mb-55">
                <h2>Featured Products</h2>
                <p>Top featured products of this week</p>
            </div>
            <Row>
                <Col className="p-1" key={1} xl={2} lg={2} sm={4} xs={6}>
                    <Link to="/product_details">
                    <Card className="image-box card">
                        <img className="center" src={sock} alt="featured prodct"/>
                        <Card.Body>
                            <p className="product-name-on-card">High quality women dress.</p>
                            <p className="product-price-on-card">Price: $99.99</p>
                        </Card.Body>
                    </Card>
                    </Link>
                </Col>
                <Col className="p-1" key={2} xl={2} lg={2} sm={4} xs={6}>
                    <Card className="image-box card">
                        <img className="center" src={dress} alt="featured prodct"/>
                        <Card.Body>
                            <p className="product-name-on-card">High quality women dress.</p>
                            <p className="product-price-on-card">Price: $99.99</p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="p-1" key={3} xl={2} lg={2} sm={4} xs={6}>
                    <Card className="image-box card">
                        <img className="center" src={dress} alt="featured prodct"/>
                        <Card.Body>
                            <p className="product-name-on-card">High quality women dress.</p>
                            <p className="product-price-on-card">Price: $99.99</p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="p-1" key={4} xl={2} lg={2} sm={4} xs={6}>
                    <Card className="image-box card">
                        <img className="center" src={dress} alt="featured prodct"/>
                        <Card.Body>
                            <p className="product-name-on-card">High quality women dress.</p>
                            <p className="product-price-on-card">Price: $99.99</p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="p-1" key={5} xl={2} lg={2} sm={4} xs={6}>
                    <Card className="image-box card">
                        <img className="center" src={dress} alt="featured prodct"/>
                        <Card.Body>
                            <p className="product-name-on-card">High quality women dress.</p>
                            <p className="product-price-on-card">Price: $99.99</p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="p-1" key={6} xl={2} lg={2} sm={4} xs={6}>
                    <Card className="image-box card">
                        <img className="center" src={dress} alt="featured prodct"/>
                        <Card.Body>
                            <p className="product-name-on-card">High quality women dress.</p>
                            <p className="product-price-on-card">Price: $99.99</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
      </Fragment>
    )
  }
}

export default FeaturedProducts