import React, { Component, Fragment } from 'react'
import { Button, Col, Container, Row, Form } from 'react-bootstrap'

export class Contact extends Component {

    submitHandler=(e)=> {
        e.preventDefault();
    }
  render() {
    return (
        <Fragment>
        <Container>
            <Row className="p-2">
                <Col className="shadow-sm bg-white" md={12} lg={12} sm={12} xs={12}>

                </Col>
                
                <Row className="text-center">
                    <Col className="d-flex justify-content-center" md={6} lg={6} sm={12} xs={12}>
                        <Form className="onboardForm" onSubmit={this.submitHandler}>
                            <h4 className="section-title-login"> CONTANT WITH US</h4>
                            <h6 className="section-sub-title">Please Enter Message and Contact</h6>
                            <input className="form-control m-2" type="number" placeholder="Enter Mobile Number" />
                            <input className="form-control m-2" type="email" placeholder="Enter Email Address" />
                            <textarea className="form-control m-2" type="email" placeholder="Enter You Message" />
                            <Button type="submit" className="btn btn-block m-2 site-btn-login"> Send </Button>
                        </Form>

                    </Col>
                    <Col className="p-0 Desktop m-0" md={6} lg={6} sm={6} xs={6}>
                        <br /><br />
                        <p className="section-title-contact">
                        Ernst-Grube-Street 97, 08062 Zwickau, Germany<br />
                        support@tanin-express.com
                        </p>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2527.1500192200338!2d12.463677124260428!3d50.69860057164055!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a72cc1a994fbd1%3A0x4f44500c454bca47!2sErnst-Grube-Stra%C3%9Fe%2097%2C%2008062%20Zwickau!5e0!3m2!1sfa!2sde!4v1684359700742!5m2!1sfa!2sde" 
                        width="550" height="400" styles="border:0;" allowfullscreen="" loading="lazy" 
                        title="contact add" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </Col>
                </Row>
                
            </Row>
        </Container>
      </Fragment>
    )
  }
}

export default Contact