import React, { Component, Fragment } from 'react'
import { Card, Container, Row } from 'react-bootstrap'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import dress4 from '../../assets/images/dress4.jpg';

export class NewArrival extends Component {
    constructor(props){
          super(props);
          this.next=this.next.bind(this);
          this.previous=this.previous.bind(this)
     }
     next(){
          this.slider.slickNext();
     }
     previous(){
          this.slider.slickPrev();
     }
  render() {
    const settings = {
        dots: false,
               infinite: true,
               speed: 500,
               autoplay: true,
               autoplaySpeed:3000,
               slidesToShow: 4,
               slidesToScroll: 1,
               initialSlide: 0,
               arrows: false,
               responsive: [
                 {
                   breakpoint: 1024,
                   settings: {
                     slidesToShow: 4,
                     slidesToScroll: 1,
                     infinite: true,
                     dots: true
                   }
                 },
                 {
                   breakpoint: 600,
                   settings: {
                     slidesToShow: 2,
                     slidesToScroll: 1,
                     initialSlide: 2
                   }
                 },
                 {
                   breakpoint: 480,
                   settings: {
                     slidesToShow: 1,
                     slidesToScroll: 1
                   }
                 }
              ]
      };
    return (
        <Fragment>
            <Container>
                <div className="section-title text-center md-55">
                    <h2>NEW ARRIVALS &nbsp;
                    <button className="btn btn-sm ml-2 site-btn" onClick={this.previous} ><i className="fa fa-angle-left"></i></button>
                    &nbsp;
                    <button className="btn btn-sm ml-2 site-btn" onClick={this.next} ><i className="fa fa-angle-right"></i></button>
                    </h2>
                    <p>New Products Are Now Available For You!</p>
                </div>
                <Row>
                <div>
        <Slider ref={c=>(this.slider=c)} {...settings}>
          <div>
            <Card className="image-box card">
                <img className="center" src={dress4} alt="new arrival"/>
                <Card.Body className="center">
                    <p className="product-name-on-card">High quality women dress.</p>
                    <p className="product-price-on-card">Price: $99.99</p>
                </Card.Body>
            </Card>
          </div>

          <div>
            <Card className="image-box card">
                <img className="center" src={dress4} alt="featured prodct"/>
                <Card.Body className="center">
                    <p className="product-name-on-card">High quality women dress.</p>
                    <p className="product-price-on-card">Price: $99.99</p>
                </Card.Body>
            </Card>
          </div>

          <div>
            <Card className="image-box card">
                <img className="center" src={dress4} alt="featured prodct"/>
                <Card.Body className="center">
                    <p className="product-name-on-card">High quality women dress.</p>
                    <p className="product-price-on-card">Price: $99.99</p>
                </Card.Body>
            </Card>
          </div>

          <div>
            <Card className="image-box card">
                <img className="center" src={dress4} alt="featured prodct"/>
                <Card.Body className="center">
                    <p className="product-name-on-card">High quality women dress.</p>
                    <p className="product-price-on-card">Price: $99.99</p>
                </Card.Body>
            </Card>
          </div>

          <div>
            <h3>5</h3>
          </div>
          <div>
            <h3>6</h3>
          </div>
        </Slider>
      </div>
                </Row>
            </Container>
        </Fragment>
    )
  }
}

export default NewArrival