import React, { Component } from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import slide_imag1 from '../../assets/images/WG751.png';
import slide_image2 from '../../assets/images/WL209.png';
import slide_image3 from '../../assets/images/MG09.png';


export class HomeSlider extends Component {
  render() {
    const settings = {
        dots: false,
               infinite: true,
               speed: 500,
               autoplay: true,
               autoplaySpeed:3000,
               slidesToShow: 1,
               slidesToScroll: 1,
               initialSlide: 0,
               arrows: false,
               responsive: [
                 {
                   breakpoint: 1024,
                   settings: {
                     slidesToShow: 1,
                     slidesToScroll: 1,
                     infinite: true,
                     dots: true
                   }
                 },
                 {
                   breakpoint: 600,
                   settings: {
                     slidesToShow: 1,
                     slidesToScroll: 1,
                     initialSlide: 2
                   }
                 },
                 {
                   breakpoint: 480,
                   settings: {
                     slidesToShow: 1,
                     slidesToScroll: 1
                   }
                 }
              ]
      };
    return (
      <div>
        <Slider {...settings} className='home-slider'>
            <div>
               <img className='slider-img' src={slide_imag1} alt='home slider' />
            </div>
            <div>
            <img className='slider-img' src={slide_image2} alt='home slider' />
            </div>
            <div>
            <img className='slider-img' src={slide_image3} alt='home slider' />
            </div>
        </Slider>
      </div>
    )
  }
}

export default HomeSlider