import React, { Component, Fragment } from 'react'
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './route/AppRouter';
import "./index.css";

export class App extends Component {
  render() {
    return (
      <Fragment>
        <BrowserRouter>
          <AppRouter />
        </BrowserRouter>
      </Fragment>
    )
  }
}

export default App