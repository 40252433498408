import React, { Component, Fragment } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import sock from "../../assets/images/product/MG09_1.png";
import dress from '../../assets/images/dress3.jpg';

export class SuggestedProducts extends Component {
  render() {
    return (
        <Fragment>
        <Container className="text-center" fluid={"true"}>
            <div className="section-title text-center mb-55">
                <h2>YOU MAY ALSO LIKE</h2>
                <p>List of similar products from our collections for you</p>
            </div>
            <Row>
                <Col className="p-1" key={1} xl={2} lg={2} sm={4} xs={6}>
                    <Link to="/product_details">
                    <Card className="image-box card">
                        <img className="center" src={sock} alt="featured prodct"/>
                        <Card.Body>
                            <p className="product-name-on-card">High quality women dress.</p>
                            <p className="product-price-on-card">Price: $99.99</p>
                        </Card.Body>
                    </Card>
                    </Link>
                </Col>
                <Col className="p-1" key={1} xl={2} lg={2} sm={4} xs={6}>
                    <Card className="image-box card">
                        <img className="center" src={dress} alt="featured prodct"/>
                        <Card.Body>
                            <p className="product-name-on-card">High quality women dress.</p>
                            <p className="product-price-on-card">Price: $99.99</p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="p-1" key={1} xl={2} lg={2} sm={4} xs={6}>
                    <Card className="image-box card">
                        <img className="center" src={dress} alt="featured prodct"/>
                        <Card.Body>
                            <p className="product-name-on-card">High quality women dress.</p>
                            <p className="product-price-on-card">Price: $99.99</p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="p-1" key={1} xl={2} lg={2} sm={4} xs={6}>
                    <Card className="image-box card">
                        <img className="center" src={dress} alt="featured prodct"/>
                        <Card.Body>
                            <p className="product-name-on-card">High quality women dress.</p>
                            <p className="product-price-on-card">Price: $99.99</p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="p-1" key={1} xl={2} lg={2} sm={4} xs={6}>
                    <Card className="image-box card">
                        <img className="center" src={dress} alt="featured prodct"/>
                        <Card.Body>
                            <p className="product-name-on-card">High quality women dress.</p>
                            <p className="product-price-on-card">Price: $99.99</p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="p-1" key={1} xl={2} lg={2} sm={4} xs={6}>
                    <Card className="image-box card">
                        <img className="center" src={dress} alt="featured prodct"/>
                        <Card.Body>
                            <p className="product-name-on-card">High quality women dress.</p>
                            <p className="product-price-on-card">Price: $99.99</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
      </Fragment>
    )
  }
}

export default SuggestedProducts