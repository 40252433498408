import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import appStor from '../../assets/images/appstore.png';
import playStor from '../../assets/images/playstore.png';


export class FooterMobile extends Component {
  render() {
    return (
      <Fragment>
        <div className="footerback shadow-sm m-0 pt-5">
        <Container className="text-center">
          <Row>
            <Col className="p-2" lg={3} md={3} sm={6} xs={12}>
              <h5 className="footer-menu-title">OFFICE ADDRESS</h5>
              <p>Ernst-Grube-Street 97, 08062 Zwickau, Germany<br />
                Email: support@tanin-express.com
              </p>
              <h5 className="footer-menu-title">SOCIAL LINK</h5>
              <a href='/'><i className="fab m-1 h4 fa-facebook"></i></a>
              <a href='/'><i className="fab m-1 h4 fa-instagram"></i></a>
              <a href='/'><i className="fab m-1 h4 fa-linkedin"></i></a>
            </Col>
            
            <Col className="p-2" lg={3} md={3} sm={6} xs={12}>
              <h5 className="footer-menu-title">GET APP</h5>
              <a href="/"><img className="footer-logo" src={appStor} alt='app store' /></a><br />
              <a href="/"><img className="footer-logo" src={playStor} alt='app store' /></a>
            </Col>
          </Row>
        </Container>
        <Container fluid={"true"} className="text-center m-0 pt-3 pb-1">
          <Container>
            <Row>
              <h6>@ Copyright 2023 by Tanin Express</h6>
            </Row>
          </Container>
        </Container>
        </div>
      </Fragment>
    )
  }
}

export default FooterMobile