import React, { Component, Fragment } from 'react'
import Notification from '../components/Notification/Notification'
import NavMenuDesktop from '../components/common/NavMenuDesktop'
import NavMenuMobile from '../components/common/NavMenuMobile'
import FooterDesktop from '../components/common/FooterDesktop'
import FooterMobile from '../components/common/FooterMobile'

export class NotificationPage extends Component {
  render() {
    return (
        <Fragment> 
        <div className="Desktop">
          <NavMenuDesktop />
        </div>

        <div className="Mobile">
          <NavMenuMobile />  
          
        </div>    
            <Notification />                   
                    
        <div className="Desktop">
          <FooterDesktop />
        </div>
        <div className="Mobile">
          <FooterMobile />
        </div>
        
      </Fragment>
    )
  }
}

export default NotificationPage