import React, { Component, Fragment } from 'react'
import NavMenuDesktop from '../components/common/NavMenuDesktop'
import NavMenuMobile from '../components/common/NavMenuMobile'
import FooterDesktop from '../components/common/FooterDesktop'
import FooterMobile from '../components/common/FooterMobile'
import UserLogin from '../components/common/UserLogin'

export class LoginPage extends Component {
  
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
        <Fragment> 
        <div className="Desktop">
          <NavMenuDesktop />
        </div>

        <div className="Mobile">
          <NavMenuMobile />  
          
        </div>    
        <UserLogin />                   
                    
        <div className="Desktop">
          <FooterDesktop />
        </div>
        <div className="Mobile">
          <FooterMobile />
        </div>
        
      </Fragment>
    )
  }
}

export default LoginPage